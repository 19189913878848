import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Tabs, Tab, Nav } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import dateFormat from 'dateformat';
import NoImg from '../../../images/no-image.png';

import PlayVideo from '../../../components/Play/PlayVideo';
import {youtube_parser, CustomLinks} from "../../common/utils";
import {ImageModule} from "../../../modules/Image_Module";
import {RelatedInsights} from "../../../queries/common_use_query";
import "./News.scss";
import HTMLReactParser from 'html-react-parser';

// Header component

const PeopleInsights = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [newsList, setNewsList] = useState([]);

    useEffect(()=>{
        props.insights && setNewsList(props.insights);
    },[props.insights])


    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: newsList && newsList.length > 3 ? true : false,
        arrows: false,
        mobileFirst: true,
        pauseOnHover:false,
        autoplay:true,
        autoplaySpeed:3000,
        dots: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: newsList && newsList.length > 2 ? true : false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: newsList && newsList.length > 1 ? true : false,
                },
            },
        ],
    };


    //console.log("newsAndInsights", data && data.newsAndInsights, tabKey);

    var setting_val = {...settings, infinite:newsList && newsList.length > 3 ? true : false};

    return (
        <React.Fragment>
            {
                newsList.length > 0 && <>
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                        <section className={`news-section white`}>
                            <Container>
                                <Row>
                                    <Col className="news-content">
                                        <div className="news-heading">
                                            <h2>{props.Title ? props.Title : "Related News"}</h2>
                                        </div>

                                        <Tab.Container defaultActiveKey={"0"}>

                                            <Tab.Content>
                                                <Tab.Pane eventKey={"0"}>
                                                        {  
                                                            <Slider {...setting_val}>
                                                            {
                                                                newsList.map((item, index) => {
                                                                    var youtube_id = item.Embed_Video_URL && item.Embed_Video_URL && youtube_parser(item.Embed_Video_URL);
                                                                    return(
                                                                        <div className="news-wrapper">
                                                                            
                                                                            <div className="news-img img-zoom">
                                                                                <Link to={`${CustomLinks.insights}/${item.URL}`}>
                                                                                <picture>
                                                                                    {item.Image ?  
                                                                                        <ImageModule ImageSrc={item.Image} ggfx_results={item.ggfx_results} 
                                                                                        altText={item.Name + " - Strettons"} imagename="news-and-insights.Image.small_image" strapi_id={item.id} />
                                                                                        : <img src={NoImg} alt={item.Name} /> 
                                                                                    }
                                                                                </picture>
                                                                                </Link>
                                                                                {
                                                                                    item.Embed_Video_URL && 
                                                                                    <div className="video-buttons" onClick={(e) => { setVideoId(youtube_id);  setPlay(true) }}>
                                                                                        <strong className="video-btn">
                                                                                            <i className="icon-video-black"></i>
                                                                                        </strong>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            
                                                                            <div className="news-details">
                                                                                {/* <h5>
                                                                                    {dateFormat(item.Published_Date, "mmmm dd yyyy")} / {item.news_and_insights_category.Name}
                                                                                </h5> */}
                                                                                <h5>
                                                                                    {item.news_and_insights_category.Name}
                                                                                </h5>
                                                                                <h2>
                                                                                    <Link to={`${CustomLinks.insights}/${item.URL}`}>
                                                                                        {item.Title}
                                                                                    </Link>
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }                                       
                                                            </Slider>
                                                        }
                                                </Tab.Pane>
                                            </Tab.Content>
                                                
                                        </Tab.Container>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                        </ScrollAnimation>

                        <PlayVideo
                        isOpen={isPlay}
                        isCloseFunction={setPlay}
                        videoId={videoId}
                        isAutoPlay={1}
                        />
                </>
            }
            
        </React.Fragment>
    )
}
export default PeopleInsights
